import client from './requestclient';

const partners = {
    dashboard: async (id) => {
        return await client({URL_PATH: `partner-dashboard/${id}`, method: "GET"})
    },

    userCheck: async (id) => {
        return await client({URL_PATH: `check-user-profiles/${id}`, method: "GET"})
    },
    
}

export default partners;