<template>
    <div>
        <div class="container-fluid2">
            <h3 class="overview-text">Overview</h3>
            <h3 class="wallet-balance-text" v-if="dashboardData.wallet_balance">Wallet Balance: <span>₦{{Number(dashboardData.wallet_balance).toLocaleString() || 0}}</span></h3>
            <div class="">
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-flex justify-content-between top-card">
                            <div>
                                <h6>Total Earnings</h6>
                                <h2 v-if="dashboardData.total_earnings">₦{{Number(dashboardData.total_earnings).toLocaleString() || 0}}</h2>
                            </div>
                            <img src="../assets/icons/pdi2.svg" alt="icon">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-between top-card">
                            <div>
                                <h6>Properties Managed</h6>
                                <h2>{{dashboardData.properties || 0}}</h2>
                            </div>
                            <img src="../assets/icons/pdi4.svg" alt="icon">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-between top-card">
                            <div>
                                <h6>Landlords Managed</h6>
                                <h2>{{dashboardData.landlord_count || 0}}</h2>
                            </div>
                            <img src="../assets/icons/pdi3.svg" alt="icon">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-between top-card">
                            <div>
                                <h6>FM Managed</h6>
                                <h2>{{dashboardData.fms || 0}}</h2>
                            </div>
                            <img src="../assets/icons/pdi1.svg" alt="icon">
                        </div>
                    </div>
                </div>
            </div>

            <!-- Task section and Earnings -->
            <div class="earnings-task-holder">
                <div class="row">
                    <div class="col-md-8">
                        <div class="bar-chart-holder">
                            <h4>Earnings</h4>
                            <div class="form-group">
                                <select id="vouchertype" class="form-control input-field col-md-2" >
                                    <option selected value="">Monthly</option>
                                    <!-- <option>Yearly</option> -->
                                </select>
                            </div>
                            <hr>
                            <div>
                                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="task-holder">
                            <div class="task-header">
                                <h5>My Tasks (0)</h5>
                                <h6>View all <span><i class="fa fa-chevron-right"></i></span></h6>
                            </div>
                            <div class="tasks-body">
                                <EmptyState :emptyData="emptyDataObject2" />
                            </div>
                            <div class="task-body d-none">
                                <div class="tasks-list">
                                    <div class="task-row">
                                        <div class="round-dot"></div>
                                        <div>
                                            <h5>Property Issues</h5>
                                            <h6>3 Maintenance request pending</h6>
                                        </div>
                                    </div>
                                    <div class="task-buttons">
                                        <button class="btn">View details</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="tasks-list">
                                    <div class="task-row">
                                        <div class="round-dot"></div>
                                        <div>
                                            <h5>Vendor Approval</h5>
                                            <h6>Review and approve vendor request</h6>
                                        </div>
                                    </div>
                                    <div class="task-buttons">
                                        <button class="btn">Approve now</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="tasks-list">
                                    <div class="task-row">
                                        <div class="round-dot"></div>
                                        <div>
                                            <h5>Tenant Issues</h5>
                                            <h6>3 Tenants have overdue rent</h6>
                                        </div>
                                    </div>
                                    <div class="task-buttons">
                                        <button class="btn">Send reminder</button>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <!-- Task section and Earnings -->

            <!-- Activity tracking -->
            <div class="activity-holder">
                <h3 class="overview-text">Overview</h3>

                <div class="actiivity-buttons-holder">
                    <div v-for="(item, index) in activitybuttons" :key="index">
                        <button :disabled="index != 0" class="btn" :class="[activeBtnIndex === index ? 'activeBtn' : '']" @click="activeBtnIndex = index">{{item.name}}</button>
                        <!-- <button class="btn activeBtn">Complaints</button> -->
                    </div>
                </div>

                <!-- <div class="form-group d-flex justify-content-between">
                    <div class="form-group col-md-6" style="padding-left: 0; padding-right: 0; gap: 10px;">
                        <input class="form-control input-field search" type="text" name="search" id="search" placeholder="Search...">
                    </div>
                    <div class="form-group">
                        <select id="vouchertype" class="form-control input-field" >
                            <option selected value="">Status</option>
                            <option>Resolved</option>
                            <option>Pending</option>
                            <option>Ongoing</option>
                        </select>
                    </div>
                </div> -->
                <div class="table-responsive tableList">
                    <div class="m-auto text-center mt-4" v-if="loadingDashboard"><Loader/></div>
                    <table class="table" v-if="!loadingDashboard && assingedFMArray.length != 0">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Full name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone number</th>
                                <th scope="col">Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in assingedFMArray"
                                :key="index"
                                :value="item.id">
                                <td>{{index + 1}}</td>
                                <td>{{item.user.firstname}} {{item.user.lastname}}</td>
                                <td>{{item.user.email}}</td>
                                <td>{{item.user.phone}}</td>
                                <td>{{ item.user.address }}</td>
                                <!-- <td>
                                    <img class="dropdown-toggle" style="cursor: pointer;" src="../assets/icons/menu-icon.svg" alt="menu" data-toggle="dropdown" aria-expanded="false">
                                    <div class="dropdown-menu">
                                    <a @click="viewLandlordDetails(item.id)" class="dropdown-item">View details</a>
                                    <a @click="addProperty(item.id)" class="dropdown-item">Add property (unit)</a>
                                    <a class="dropdown-item">Generate report</a>
                                    <a class="dropdown-item">Delete</a>
                                    </div>
                                </td> -->
                            </tr>
                           
                        </tbody>
                    </table>
                    <div v-if="assingedFMArray.length == 0 && !loadingDashboard">
                        <EmptyState :emptyData="emptyDataObject" />
                    </div>
                </div>
            </div>
            <!-- Activity tracking -->
        </div>
    </div>
</template>


<script>
import VueApexCharts from "vue-apexcharts";
import partners from '../services/partners'
import EmptyState from "../components/EmptyState.vue";
import Loader from '../components/Loader';
export default {
    name: "Dashboard",
    components: {
        apexchart: VueApexCharts,
        Loader,
        EmptyState
    },
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            activeBtnIndex: 0,
            loadingDashboard: false,
            dashboardData: "",
            userData: "",
            assingedFMArray: [],
            userID: "",
            activitybuttons: [
                {
                    name: "Assigned FM",
                },
                {
                    name: "Due rents",
                },
                {
                    name: "Complaints",
                },
                {
                    name: "Inspections",
                },
                {
                    name: "Maintenance",
                },
            ],
            emptyDataObject: {
                header: 'No record found',
                option: 'Record will be displayed when updated or avaialable'
            },
            emptyDataObject2: {
                header: 'No task yet',
                option: 'Task will be displayed when updated or avaialable'
            },
            series: [
                {
                    // data: [300, 400, 500, 600, 700, 900], // Data values
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                type: "bar",
                height: 350,
                },
                plotOptions: {
                    bar: {
                        distributed: true, // Enables distributed bars
                        borderRadius: 5, // Optional: Rounds the corners
                        columnWidth: '90%',
                    },
                },
                // colors: [
                // "#008FFB",
                // "#00E396",
                // "#FEB019",
                // "#FF4560",
                // "#775DD0",
                // "#546E7A",
                // "#26a69a",
                // "#D10CE8",
                // ], // Custom colors per bar

                colors: [
                "#62B2FD",
                "#9BDFC4",
                "#F99BAB",
                "#FFB44F",
                "#9F97F7",
                "#CED6DE",
                "#D10CE8",
                ],
                dataLabels: {
                    enabled: true,
                },
                xaxis: {
                    labels: {
                        show: false, // Hides x-axis labels
                    },
                categories: [
                    "Smart FM/IO Commission",
                    "State/National Bonus",
                    "Partners Royalties",
                    "Property Management Commission",
                    "Property Sales",
                    "Rent Administration fees",
                    "Subscription"
                ],
                },
            },
        }
    },
    mounted() {
        let user = this.$store.getters.getUser;
        this.userData = this.$store.getters.getUser;
        this.userID = user.id;
        this.fetchDashboardSummary();
    },
    methods: {
        async fetchDashboardSummary() {
            this.loadingDashboard = true;
            if(this.userID != "" || this.userID != null) {
                this.loadingDashboard = true;
                try {
                    await partners.dashboard(this.userID).then((res) => {
                    // console.log("Response", res);
                    this.loadingDashboard = false;
                    this.dashboardData = res;
                    this.graphData = res.graph_data;
                    this.assingedFMArray = res.assingedFM;
                    const valuesArray = Object.values(this.graphData);
                    this.series = [
                        {
                            data: valuesArray, // Update the chart dynamically
                        },
                    ];
                    }).catch((err) => {
                        console.log(err);
                        this.loadingDashboard = false;
                    });
                } finally {
                    // console.log("stoped");
                }
            }
        }
    },
}
</script>

<style scoped lang="scss">

.overview-text {
    color: #2D3E50;
    font-family: Lato;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;

}
.wallet-balance-text {
    color: #2D3E50;
    font-family: Lato;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    width: fit-content;
    background: #e8e9f1;
    padding: 10px;
    border-radius: 5px;
    span {
        color: #222529;
    }
}   
.top-card {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #E5E7EB;
    padding: 16px 16px;
    margin-bottom: 20px;
    h6 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        color: #555C74
    }
    h2 {
        font-family: Lato;
        font-weight: 700;
        font-size: 20px;
        color: #2B3352;

    }
}

.earnings-task-holder {
    margin-bottom: 30px;
    border-radius: 10px;
}
.bar-chart-holder {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    h4 {
        font-family: Inter;
        font-weight: 700;
        font-size: 19.18px;
        color: #222529;

    }
}
.task-holder {
    background: #ffffff;
    border-radius: 10px;
    padding: 24px 20px;
}

.task-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h5 {
        font-family: Lato;
        font-weight: 600;
        font-size: 21px;
        color: #2D3E50;
    }
    h6 {
        font-family: Lato;
        font-weight: 600;
        font-size: 11px;
        color: #0057FF;
        cursor: pointer;
    }
}

.tasks-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
}
.task-row {
    display: flex;
    align-items: center;
    gap: 15px;
    h5 {
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #2B3352;
    }
    h6 {
        font-family: Lato;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
       color: #555C74;

    }


}

.round-dot {
    width: 12px;
    height: 12px;
    background: #F99BAB;
    border-radius: 50px;
}

.task-buttons {
    button {
        border: 1px solid #0057FF;
        font-family: Lato;
        font-weight: 500;
        font-size: 13.16px;
        color: #0057FF;
        white-space: nowrap;
    }
}

.activity-holder {
    background: #ffffff;
    padding: 30px 16px;
    border-radius: 8px;
}

.actiivity-buttons-holder {
    overflow: auto;
    display: flex;
    margin-bottom: 30px;
    button {
        font-family: Lato;
        // font-weight: 500;
        font-size: 16px;
        color: #555C74;
        box-shadow: none;
        outline: 0;
        white-space: nowrap;
    }

}
.activeBtn {
    background: #0033EA;
    border-radius: 50px;
    color: #ffffff !important;
    font-family: Lato;
    // font-weight: 600;
    font-size: 14px;

}

.tableList {
    // margin-top: 30px;
    margin-bottom: 20px;
    border: 2px solid #EAECF0;
    border-radius: 20px;
}


.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #EAECF0;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #667085
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085
}

.activeVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #067647;
    background: #ECFDF3;
    border-radius: 50px;
    padding: 4px 8px;
    // text-align: center;

}
.nonActiveVoucher {
    font-family: Lato;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // text-align: center;
    color: #555C74;
    background: #FAFAFA;
    border-radius: 50px;
    padding: 4px 8px;
}

.input-field {
    height: 48px;
    outline: 0;
    box-shadow: none;
}

.search {
   width: 70%; 
}



@media screen and (max-width: 599px) {
    #vouchertype {
        //  width: 100%; 
            width: 123px;
    }   
    .task-row {
        gap: 10px;
    }

}



</style>